<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="4">
        <v-card elevation="1" class="mx-auto" color="#3c9bf9">
          <v-card-title>Login</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Password"
                type="password"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row justify="end" class="pa-2">
              <v-btn depressed color="success" @click="signIn" class="pr">
                Login
              </v-btn>
              <v-overlay absolute :value="loading" :opacity="0.7">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      loading: false,
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    signIn() {
      this.$refs.form.validate();
      this.loading = true;
      let f = {
        email: this.email,
        password: this.password,
      };
      this.api("auth/login", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            localStorage.setItem("hp_user", JSON.stringify(response.user));
            localStorage.setItem("hp_token", response.token);
            localStorage.setItem("hp_menu", JSON.stringify(response.menu));
            document.location.href = "/";
          } else {
            this.handleError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "SignIn",
};
</script>
